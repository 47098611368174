import { FiltersType } from 'views/developer/dashboard/leads/components/Filters'
import apiRequest from 'utils/apiRequest'
import { RequestType } from 'types'

const getLeads = async ({
  filters,
  limit,
  page,
}: {
  filters?: FiltersType
  limit?: number
  page?: number
}) => {
  const params: {
    limit?: number
    page?: number
    isFeedbackPositive?: boolean
    hasFeedback?: boolean
    specification?: string
  } = {
    limit,
    page,
    specification: filters?.specification,
  }

  if (filters?.review !== undefined) {
    if (filters.review === 'positive' || filters.review === 'not_positive') {
      params.isFeedbackPositive = filters.review === 'positive'
      params.hasFeedback = true
    } else if (filters.review === 'pending') {
      params.hasFeedback = false
    }
  }

  const result = await apiRequest({
    type: RequestType.GET,
    url: `${process.env.REACT_APP_SERVER_URL}/api/v1/leads`,
    params,
  })

  return result
}

export default getLeads
