import { RouterProvider } from '@tanstack/react-router'
import React, { useEffect } from 'react'
import { ConfigProvider } from 'antd'
import ReactGA from 'react-ga4'
import 'utils/i18n'

import QueryClientProvider from 'components/commons/QueryClientProvider'
import { router } from 'components/commons/Router'
import awsRum from 'utils/awsCloudWatch'
import { Environment } from 'types'

import theme from 'styles/theme'

const { REACT_APP_GOOGLE_ANALYTICS_ID, REACT_APP_ENVIRONMENT } = process.env

const customTheme = {
  token: {
    colorPrimary: theme.colors.corn,
  },
  components: {
    Input: {
      hoverBorderColor: theme.colors.corn,
      activeBorderColor: theme.colors.corn,
    },
    Select: {
      colorPrimaryHover: theme.colors.corn,
      optionSelectedBg: theme.colors.corn,
    },
  },
}

const App: React.FC = () => {
  if (REACT_APP_GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_ID, {
      gaOptions: {
        send_page_view: false,
      },
    })
  }

  useEffect(() => {
    const setupRum = async () => {
      await awsRum()
    }

    const isProductionOrStagingEnv = [
      Environment.PRODUCTION,
      Environment.STAGING,
    ].includes(REACT_APP_ENVIRONMENT as Environment)

    if (isProductionOrStagingEnv) setupRum()
  }, [])

  return (
    <QueryClientProvider>
      <ConfigProvider theme={customTheme}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </QueryClientProvider>
  )
}

export default App
