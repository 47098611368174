import { useTranslation } from 'react-i18next'
import { Link } from '@tanstack/react-router'

import RestrictedWarningSection from 'components/commons/LeadCard/components/RestrictedWarningSection'
import CriteriaProgressSection from 'components/commons/LeadCard/components/CriteriaProgressSection'
import PictureSection from 'components/commons/LeadCard/components/PictureSection'
import Button from 'components/commons/Button'
import { Project } from 'types'

const LeadCard = ({ project }: { project: Project }) => {
  const { t } = useTranslation()

  const { uuid, area, scores, isFeedbackPositive, hasFeedback } = project

  const isViewable = !!(scores?.length && scores.length > 0)

  return (
    <Link
      to={`/developer/dashboard/project/${uuid}`}
      onClick={isViewable ? undefined : (e) => e.preventDefault()}
      className="block h-full"
    >
      <div
        className={`flex h-full flex-col rounded-2xl bg-white p-5 drop-shadow-sm ${
          scores ? 'hover:cursor-pointer' : 'hover:cursor-default'
        } ${isFeedbackPositive ? 'border-4 border-grass' : 'border-4 border-white'}`}
      >
        <PictureSection
          hasFeedback={hasFeedback}
          isFeedbackPositive={isFeedbackPositive}
          isViewable={isViewable}
          area={area}
          uuid={uuid}
        />

        <div className="my-7 flex flex-1 items-center justify-center">
          {isViewable ? (
            <CriteriaProgressSection scores={scores} />
          ) : (
            <RestrictedWarningSection />
          )}
        </div>

        <div>
          <Button
            style={isViewable ? 'primary' : 'disabled'}
            loading={false}
            className="w-full"
          >
            {t('commonsComponents.leadCard.actionButtonMessage')}
          </Button>
        </div>
      </div>
    </Link>
  )
}

export default LeadCard
