import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import SubstationQueueItemDetails from 'views/developer/dashboard/lead/components/SubstationQueueItemDetails'
import SubstationDetails from 'views/developer/dashboard/lead/components/SubstationDetails'
import ParcelsModal from 'views/developer/dashboard/lead/components/ParcelsModal'
import CheckFatIcon from 'components/SVG/Icons/CheckFatIcon'
import sendGACustomEvent from 'utils/gaCustomEventSender'
import CloseIcon from 'components/SVG/Icons/CloseIcon'
import MapIcon from 'components/SVG/Icons/MapIcon'
import {
  CRITERON_TYPE,
  Criterion as CriterionType,
  GAEventAction,
  GAEventContext,
  GAEventElement,
  SubstationCriterionData,
  SubstationQueueItemType,
} from 'types'

import theme from 'styles/theme'

const Criterion = ({
  criterion,
  selectedPicturePath,
  handleChangePicturePath,
}: {
  criterion: CriterionType
  handleChangePicturePath: (value?: string) => void
  selectedPicturePath?: string
}) => {
  const [isButtonActive, setIsButtonActive] = useState(false)
  const { t } = useTranslation()

  const bgColor =
    (criterion.type === CRITERON_TYPE.WARNING && 'bg-sun') ||
    (criterion.type === CRITERON_TYPE.OK && 'bg-grass') ||
    (criterion.type === CRITERON_TYPE.NOT_OK && 'bg-coquelicot') ||
    (criterion.type === CRITERON_TYPE.INFO && 'bg-wind')

  const textColor =
    (criterion.type === CRITERON_TYPE.WARNING && 'text-strongSun') ||
    (criterion.type === CRITERON_TYPE.OK && 'text-strongGrass') ||
    (criterion.type === CRITERON_TYPE.NOT_OK && 'text-white') ||
    (criterion.type === CRITERON_TYPE.INFO && 'text-black')

  const strongColorJs =
    (criterion.type === CRITERON_TYPE.WARNING && theme.colors.strongSun) ||
    (criterion.type === CRITERON_TYPE.OK && theme.colors.strongGrass) ||
    (criterion.type === CRITERON_TYPE.INFO && 'black') ||
    (criterion.type === CRITERON_TYPE.NOT_OK && 'white') ||
    'black'

  const renderValueOrIcon = () => {
    if (criterion.value)
      return (
        <div className="text-left text-sm font-bold lg:text-right">
          {criterion.value}
        </div>
      )

    if (typeof criterion.value === 'boolean') {
      return (
        <div>
          {criterion.value === true && (
            <CheckFatIcon size={20} color={theme.colors.strongGrass} />
          )}
          {criterion.value === false && <CloseIcon size={20} color="white" />}
        </div>
      )
    } else {
      if (criterion.type === CRITERON_TYPE.OK) {
        return <CheckFatIcon size={20} color={strongColorJs} />
      } else if (criterion.type === CRITERON_TYPE.NOT_OK) {
        return <CloseIcon size={20} color={strongColorJs} />
      }
      return null
    }
  }

  const handleButtonClick = () => {
    sendGACustomEvent({
      context: GAEventContext.LEAD,
      element: GAEventElement.IMAGE,
      action: GAEventAction.CHANGE,
    })

    if (selectedPicturePath === criterion.criterionMapPicturePath)
      handleChangePicturePath()
    else if (criterion.criterionMapPicturePath)
      handleChangePicturePath(criterion.criterionMapPicturePath!)
    setIsButtonActive(!isButtonActive)
  }

  useEffect(() => {
    if (selectedPicturePath !== criterion.criterionMapPicturePath)
      setIsButtonActive(false)
    if (selectedPicturePath === criterion.criterionMapPicturePath)
      setIsButtonActive(true)
  }, [selectedPicturePath, criterion.criterionMapPicturePath])

  return (
    <div
      className={`${textColor} flew-col flex w-full flex-1 items-start gap-4 rounded-lg ${bgColor} flex-col overflow-hidden`}
    >
      <div className="flex w-full">
        {criterion.criterionMapPicturePath && (
          <div
            className={`relative self-stretch p-4 hover:cursor-pointer lg:w-[80px] lg:min-w-[80px]`}
            onClick={handleButtonClick}
          >
            <div
              className={`absolute inset-0 ${bgColor} bg-blend-normal ${isButtonActive ? 'brightness-75' : 'brightness-90'}`}
            />

            <div className="relative flex h-full flex-col items-center justify-center">
              <MapIcon size={25} color={strongColorJs} />
              <span className={`${textColor} text-sm`}>
                {isButtonActive ? t('commons.hide') : t('commons.view')}
              </span>
            </div>
          </div>
        )}

        <div className="flex w-full flex-col gap-4 p-4">
          <div
            className={`flex flex-1 flex-col items-start justify-start gap-10 lg:flex-row lg:justify-between lg:gap-4 lg:p-0`}
          >
            <div>
              <div className="text-sm font-bold">{t(criterion.title)}</div>
              {criterion.description && (
                <div className="text-sm">{t(criterion.description)}</div>
              )}

              {criterion.items &&
                criterion.key !== 'parcels' &&
                criterion.key !== 'substation' &&
                criterion.key !== 'substation_queue_item' &&
                criterion.items.map((item) => (
                  <div
                    className="flex items-center gap-2 text-sm"
                    key={item.title}
                  >
                    {item.type === 'ok' && (
                      <CheckFatIcon color={strongColorJs} size={15} />
                    )}

                    {item.type === 'nok' && (
                      <CloseIcon color={strongColorJs} size={15} />
                    )}
                    {t(item.title)}
                  </div>
                ))}
            </div>

            {renderValueOrIcon()}
          </div>

          {criterion?.key === 'substation' && (
            <SubstationDetails
              substation={criterion.data as SubstationCriterionData}
            />
          )}

          {criterion?.key === 'substation_queue_item' && (
            <SubstationQueueItemDetails
              item={criterion as SubstationQueueItemType}
            />
          )}
        </div>
      </div>

      {criterion.items &&
        criterion.key === 'parcels' &&
        !!criterion.items.length && <ParcelsModal items={criterion.items} />}
    </div>
  )
}

export default Criterion
