import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import FeedbackForm from 'views/developer/dashboard/lead/components/FeedbackForm'
import { GAEventAction, GAEventContext, GAEventElement, Project } from 'types'
import LockOpenedIcon from 'components/SVG/Icons/LockOpenedIcon'
import ThumbDownIcon from 'components/SVG/Icons/ThumbDownIcon'
import ThumbUpIcon from 'components/SVG/Icons/ThumbUpIcon'
import sendGACustomEvent from 'utils/gaCustomEventSender'
import CustomCard from 'components/commons/CustomCard'

import theme from 'styles/theme'

const VoteBar = ({
  projectUuid,
  handleGiveFeedback,
}: {
  projectUuid: string
  handleGiveFeedback: (value: boolean) => void
}) => {
  const { t } = useTranslation()

  const { data: project } = useQuery<Project>({
    queryKey: ['project', projectUuid],
  })

  const [choosenFeedback, setChoosenFeedback] = useState<boolean | null>(
    project?.isFeedbackPositive ?? null
  )

  const handleChooseFeedback = (value: boolean) => {
    sendGACustomEvent({
      context: GAEventContext.LEAD,
      element: GAEventElement.FEEDBACK_BUTTON,
      action: value
        ? GAEventAction.CLICK_POSITIVE
        : GAEventAction.CLICK_NOT_POSITIVE,
    })

    if (value === choosenFeedback) setChoosenFeedback(null)
    else setChoosenFeedback(value)
  }

  return (
    <CustomCard bgColor="bg-gradient-water-white">
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-1 items-center justify-center gap-4 text-center">
          <div className="w-[20px]">
            <LockOpenedIcon color="black" size={20} />
          </div>
          {t('views.developer.dashboard.lead.voteBar.provideYourFeedback')}
        </div>

        <div className="flex gap-2">
          <div
            onClick={() => handleChooseFeedback(true)}
            className={`right-2 top-2 flex h-[40px] w-[40px] items-center justify-center rounded-full ${
              choosenFeedback === false ? 'bg-wind' : 'bg-grass'
            } bg-blend-normal hover:cursor-pointer hover:brightness-90`}
          >
            <ThumbUpIcon
              size={15}
              color={
                choosenFeedback === false
                  ? theme.colors.strongWind
                  : theme.colors.strongGrass
              }
            />
          </div>
          <div
            onClick={() => handleChooseFeedback(false)}
            className={`right-2 top-2 flex h-[40px] w-[40px] items-center justify-center rounded-full ${
              choosenFeedback === true ? 'bg-wind' : 'bg-coquelicot'
            } bg-blend-normal hover:cursor-pointer hover:brightness-90`}
          >
            <ThumbDownIcon
              size={15}
              color={
                choosenFeedback === true ? theme.colors.strongWind : 'white'
              }
            />
          </div>
        </div>
      </div>
      <div
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          choosenFeedback === true || choosenFeedback === false
            ? 'max-h-[1000px]'
            : 'max-h-0'
        }`}
      >
        {(choosenFeedback === true || choosenFeedback === false) && (
          <FeedbackForm
            projectUuid={projectUuid}
            choosenFeedback={choosenFeedback}
            handleGiveFeedback={handleGiveFeedback}
          />
        )}
      </div>
    </CustomCard>
  )
}

export default VoteBar
