import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import getLeadImageSignedUrl from 'actions/get-lead-image-signed-url'
import BrokenImageIcon from 'components/SVG/Icons/BrokenImageIcon'
import Loader from 'components/commons/Loader'
import theme from 'styles/theme'

const Picture = ({
  uuid,
  selectedPicturePath,
}: {
  uuid: string
  selectedPicturePath: string
}) => {
  const [imageLoadError, setImageLoadError] = useState(false)
  const { t } = useTranslation()

  const {
    data,
    isLoading,
    error: queryError,
  } = useQuery({
    queryKey: ['project_picture', uuid, selectedPicturePath],
    queryFn: () =>
      getLeadImageSignedUrl({
        uuid: uuid,
        fileName: selectedPicturePath,
      }),
    staleTime: 0,
    gcTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const handleImageError = () => {
    setImageLoadError(true)
  }

  const hasError = queryError || imageLoadError

  useEffect(() => {
    setImageLoadError(false)
  }, [selectedPicturePath])

  return (
    <div className="relative aspect-[1] w-[100%] overflow-hidden rounded-xl bg-wind">
      {isLoading && <Loader />}

      {hasError && !isLoading && (
        <div className="flex h-full w-full items-center justify-center">
          <BrokenImageIcon size={40} color={theme.colors.strongWind} />
        </div>
      )}

      {data && !isLoading && !hasError && (
        <img
          key={Date.now()}
          src={data.url}
          alt={t(
            'views.developer.dashboard.lead.leadPageLeftPart.pictureAltTag'
          )}
          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
          draggable="false"
          onError={handleImageError}
        />
      )}
    </div>
  )
}

export default Picture
