import { ReactNode, useEffect } from 'react'
import ReactGA from 'react-ga4'

import { MENU_SIZE } from 'components/layouts/ProtectedLayout'
import ErrorDisplay from 'components/commons/ErrorDisplay'
import Breadcrumb from 'components/commons/BreadCrumb'
import Loader from 'components/commons/Loader'
import Footer from 'components/commons/Footer'

const ProtectedLayoutPageContent = ({
  children,
  analyticsPageTitle,
  childrenExtra,
  breadcrumb = true,
  isLoading,
  error,
  errorTitle,
  errorMessage,
  errorIcon,
  onPageLeave,
}: {
  children: ReactNode
  analyticsPageTitle: string
  childrenExtra?: ReactNode
  breadcrumb?: boolean
  isLoading?: boolean
  error?: Error | boolean | null
  errorTitle?: string
  errorMessage?: string
  errorIcon?: ({ size, color }: { size: number; color: string }) => JSX.Element
  onPageLeave?: () => void
}) => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      title: analyticsPageTitle,
    })

    window?.scrollTo(0, 0)

    return () => {
      onPageLeave?.()
    }
  }, [analyticsPageTitle])

  return (
    <div
      className="h-[calc(100vh-var(--menu-size))] p-10"
      style={
        {
          '--menu-size': `${MENU_SIZE}`,
        } as React.CSSProperties
      }
    >
      <div className="flex h-full flex-col gap-10">
        {(childrenExtra || breadcrumb) && (
          <div className="flex flex-wrap items-start justify-between gap-10 md:gap-5">
            {breadcrumb && (
              <div>
                <Breadcrumb />
              </div>
            )}
            {childrenExtra && <div>{childrenExtra}</div>}
          </div>
        )}

        <div className="flex flex-1 flex-col gap-5">
          {isLoading && (
            <div className="flex flex-1 items-center justify-center">
              <Loader />
            </div>
          )}

          {!isLoading && !error && children}

          {!isLoading && error && (
            <div className="flex flex-1 items-center justify-center">
              <ErrorDisplay
                title={errorTitle}
                message={errorMessage}
                error={error}
                icon={errorIcon}
              />
            </div>
          )}
        </div>

        <Footer />
      </div>
    </div>
  )
}

export default ProtectedLayoutPageContent
