import { useInfiniteQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import ProtectedLayoutPageContent from 'components/layouts/ProtectedLayoutPageContent'
import ProjectList from 'views/developer/dashboard/leads/components/ProjectList'
import Filters, {
  FiltersType,
} from 'views/developer/dashboard/leads/components/Filters'
import getLeads from 'actions/get-leads'
import { LeadsResponse } from 'types'

const Leads = () => {
  const [filters, setFilters] = useState<FiltersType>({})
  const { t } = useTranslation()

  const options = {
    initialPageParam: 1,
    getNextPageParam: (lastPage: LeadsResponse) => {
      if (!lastPage) return 1
      return lastPage.page < lastPage.pages ? lastPage.page + 1 : undefined
    },
    getPreviousPageParam: (firstPage: LeadsResponse) =>
      firstPage?.page > 1 ? firstPage?.page - 1 : undefined,
  }

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
    error,
    isLoading,
  } = useInfiniteQuery<LeadsResponse>({
    queryKey: ['infinite-projects', filters],
    queryFn: ({ pageParam }) =>
      getLeads({ filters, page: pageParam as number }),
    ...options,
    gcTime: 1000 * 60 * 60 * 3,
    staleTime: 0,
    refetchOnWindowFocus: true,
  })

  const onLoadMore = () => {
    fetchNextPage()
  }

  const projects = data?.pages.flatMap((page) => page.items) || []

  return (
    <ProtectedLayoutPageContent
      analyticsPageTitle="Leads"
      isLoading={isLoading}
      error={error}
      errorTitle={t('errors.unableLoadProjects')}
      errorMessage={t('errors.tryAgainLater')}
      childrenExtra={<Filters setFilters={setFilters} filters={filters} />}
    >
      <ProjectList
        projects={projects}
        onLoadMore={onLoadMore}
        hasMore={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </ProtectedLayoutPageContent>
  )
}

export default Leads
