import apiRequest from 'utils/apiRequest'
import { RequestType } from 'types'
import i18n from 'utils/i18n'

const getLeadKml = async ({ uuid }: { uuid: string }) => {
  const { content } = await apiRequest({
    type: RequestType.GET,
    url: `${process.env.REACT_APP_SERVER_URL}/api/v1/leads/${uuid}/kml`,
  })

  if (!content) {
    throw new Error(i18n.t('errors.kmlFileNotAvailable'))
  }

  return content
}

export default getLeadKml
