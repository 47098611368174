import { useParams } from '@tanstack/react-router'
import { useEffect } from 'react'

import ProtectedLayoutPageContent from 'components/layouts/ProtectedLayoutPageContent'
import LeadPageContent from 'views/developer/dashboard/lead/components/LeadPageContent'
import LeadPageHeader from 'views/developer/dashboard/lead/components/LeadPageHeader'
import { queryClient } from 'components/commons/QueryClientProvider'
import WarningIcon from 'components/SVG/Icons/WarningIcon'
import { getProjectById } from 'hooks/useProjects'
import i18n from 'utils/i18n'

const LeadPage = () => {
  const params = useParams({
    from: '/developer/dashboard/project/$id',
  })

  const projectUuid = params?.id || ''

  const { data: project, isLoading, error } = getProjectById(projectUuid)

  useEffect(() => {
    const handleLanguageChange = () => {
      queryClient.invalidateQueries({ queryKey: ['project', projectUuid] })
    }

    i18n.on('languageChanged', handleLanguageChange)

    return () => {
      i18n.off('languageChanged', handleLanguageChange)
    }
  }, [i18n, queryClient, projectUuid])

  return (
    <ProtectedLayoutPageContent
      analyticsPageTitle={`Project ${projectUuid}`}
      isLoading={isLoading}
      error={error}
      errorMessage="It might not exist or you can't acccess it."
      errorTitle="This project is not accessible"
      errorIcon={WarningIcon}
      childrenExtra={
        project ? <LeadPageHeader projectUuid={project.uuid} /> : undefined
      }
    >
      {project && (
        <LeadPageContent project={project} projectUuid={project.uuid} />
      )}
    </ProtectedLayoutPageContent>
  )
}

export default LeadPage
