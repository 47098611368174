import { useRouterState, Link } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import CaretRightIcon from 'components/SVG/Icons/CaretRightIcon'
import sendGACustomEvent from 'utils/gaCustomEventSender'
import useSideMenuItems from 'hooks/useSideMenuItems'
import {
  GAEventAction,
  GAEventContext,
  GAEventElement,
  SideMenuItem,
} from 'types'

import theme from 'styles/theme'

type Params = Readonly<Partial<Record<string, string>>>

const Breadcrumb = (): JSX.Element => {
  const { location } = useRouterState()
  const { t } = useTranslation()

  const params = useRouterState().matches[0]?.params as Params
  const sideMenuItems = useSideMenuItems()

  const pathnames = location.pathname.split('/').filter((x) => x)

  const breadcrumbItems = getBreadcrumbItems(
    pathnames,
    params,
    sideMenuItems.items as SideMenuItem[],
    t
  )

  const onItemClick = () => {
    sendGACustomEvent({
      context: GAEventContext.BREADCRUMB,
      element: GAEventElement.ITEM,
      action: GAEventAction.CLICK,
    })
  }

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex flex-wrap items-center space-x-1 font-secondary uppercase md:space-x-3">
        {breadcrumbItems.map((item, index) => (
          <li key={index} className="inline-flex items-center">
            {index > 0 && (
              <CaretRightIcon size={20} color={theme.colors.strongWind} />
            )}
            {item.path ? (
              <Link
                to={item.path}
                className="text-sm font-medium text-black hover:text-strongWind md:ml-1"
                onClick={onItemClick}
              >
                {item.title}
              </Link>
            ) : (
              <span className="ml-1 text-sm font-medium text-strongWind hover:cursor-default">
                {item.title}
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

const findMenuItem = (
  sideMenuItems: SideMenuItem[],
  path: string
): SideMenuItem | undefined => {
  const findInChildren = (items: SideMenuItem[]): SideMenuItem | undefined => {
    for (const item of items) {
      if (item.path === path) {
        return item
      }
      if (item.children) {
        const found = findInChildren(item.children)
        if (found) return found
      }
    }
    return undefined
  }

  return findInChildren(sideMenuItems)
}

const getBreadcrumbItems = (
  pathnames: string[],
  params: Params,
  sideMenuItems: SideMenuItem[],
  t: TFunction<'translation', undefined>
): SideMenuItem[] => {
  const items: SideMenuItem[] = []

  if (location.pathname === '/developer/dashboard')
    items.push(
      { title: t('commons.projects') },
      {
        title: t('commons.projects'),
      }
    )

  if (pathnames.includes('specification')) {
    const specificationsItem = {
      title: t('commons.specifications'),
    }

    items.push(specificationsItem)
    if (location.pathname === '/developer/dashboard/specification/new') {
      const newSpecificationItem = {
        title: t('commons.shareYourNeeds'),
      }

      items.push(newSpecificationItem)
    } else if (params.id) {
      const specificationItem = findMenuItem(
        sideMenuItems,
        `/developer/dashboard/specification/${params.id}`
      )
      if (specificationItem) {
        items.push({ title: specificationItem.title })
      } else {
        items.push({
          title: `${t('commons.specification')} ${params.id}`,
        })
      }
    }
  } else if (pathnames.includes('project') && params.id) {
    items.push({
      title: t('commons.projects'),
      path: `/developer/dashboard`,
    })

    items.push({
      title: t('commons.project'),
    })
  }

  return items
}

export default Breadcrumb
