import apiRequest from 'utils/apiRequest'
import { RequestType } from 'types'

export interface FeedbackProps {
  feedbackPositive: boolean
  feedbackContent: { question: string; answer: string }[]
}

const createLeadFeedback = async (feedback: FeedbackProps, uuid: string) => {
  const result = await apiRequest({
    type: RequestType.POST,
    url: `${process.env.REACT_APP_SERVER_URL}/api/v1/leads/${uuid}/feedback`,
    payload: feedback,
  })

  return result
}

export default createLeadFeedback
