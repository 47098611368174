import { useEffect, useState } from 'react'
import { Menu } from 'antd'

import CaretRightIcon from 'components/SVG/Icons/CaretRightIcon'
import { MENU_SIZE } from 'components/layouts/ProtectedLayout'
import CaretLeftIcon from 'components/SVG/Icons/CaretLeftIcon'
import useSideMenuItems from 'hooks/useSideMenuItems'

const Sidebar = () => {
  const sideMenuItems = useSideMenuItems()

  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem('sidebarCollapsed')
    return savedState !== null ? JSON.parse(savedState) : false
  })

  useEffect(() => {
    localStorage.setItem('sidebarCollapsed', JSON.stringify(collapsed))
  }, [collapsed])

  return (
    <div className="relative flex h-full flex-col">
      <div
        className="absolute right-0 top-[50px] z-10 flex h-[40px] w-[25px] cursor-pointer items-center justify-center rounded-[10px] rounded-l-none bg-lightCorn shadow-md"
        onClick={() => setCollapsed(!collapsed)}
        style={{ transform: 'translateX(100%)' }}
      >
        {collapsed ? (
          <CaretRightIcon size={15} color={'black'} />
        ) : (
          <CaretLeftIcon size={15} color={'black'} />
        )}
      </div>
      <div
        className="space-between z-20 flex h-full w-fit flex-col overflow-hidden bg-white"
        style={{
          minHeight: `calc(100vh - ${MENU_SIZE})`,
          maxHeight: `calc(100vh - ${MENU_SIZE})`,
        }}
      >
        <div
          className={`flex flex-1 justify-center overflow-auto ${collapsed ? 'collapsed' : 'expanded'} p-2`}
          id="sidebar-menu"
        >
          <Menu
            selectedKeys={sideMenuItems.selectedKeys}
            mode={'vertical'}
            theme="light"
            triggerSubMenuAction="hover"
            items={sideMenuItems.items}
            getPopupContainer={(node) => node.parentNode as HTMLElement}
            className="flex flex-col gap-0 border-r-white"
          />
        </div>
      </div>
    </div>
  )
}

export default Sidebar
