import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { useForm, useController, SubmitHandler } from 'react-hook-form'
import { Form, Input, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import emailjs from '@emailjs/browser'

import createLeadFeedback, { FeedbackProps } from 'actions/create-lead-feedback'
import sendGACustomEvent from 'utils/gaCustomEventSender'
import Button from 'components/commons/Button'
import {
  ENVIRONMENT_TYPE,
  GAEventAction,
  GAEventContext,
  GAEventElement,
  User,
} from 'types'

const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY
const EMAILJS_FEEDBACK_TEMPLATE_ID =
  process.env.REACT_APP_EMAILJS_FEEDBACK_TEMPLATE_ID
const EMAILJS_ERROR_TEMPLATE_ID =
  process.env.REACT_APP_EMAILJS_ERROR_TEMPLATE_ID
const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID
const EMAILJS_FEEDBACK_TO_EMAIL =
  process.env.REACT_APP_EMAILJS_FEEDBACK_TO_EMAIL
const EMAILJS_SUPPORT_TO_EMAIL = process.env.REACT_APP_EMAILJS_SUPPORT_TO_EMAIL

type Inputs = {
  question1: string
  question2: string
  question3: string
}

type FeedbackQuestion = {
  key: keyof Inputs
  translationKey: string
  required?: boolean
  placeholder?: string
}

const FEEDBACK_QUESTIONS: FeedbackQuestion[] = [
  {
    key: 'question1',
    translationKey:
      'views.developer.dashboard.lead.feedbackForm.shareVisionRisksQuestion',
    required: true,
  },
  {
    key: 'question2',
    translationKey:
      'views.developer.dashboard.lead.feedbackForm.reasonForDisagreementQuestion',
    required: false,
  },
  {
    key: 'question3',
    translationKey:
      'views.developer.dashboard.lead.feedbackForm.additionalCriteriaQuestion',
    required: true,
  },
]

const FeedbackForm = ({
  projectUuid,
  choosenFeedback,
  handleGiveFeedback,
}: {
  projectUuid: string
  choosenFeedback: boolean | null
  handleGiveFeedback: (value: boolean) => void
}) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false)

  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: () => {
      const userData = queryClient.getQueryData<User>(['user'])
      return userData || null
    },
  })

  const feedbackMutation = useMutation({
    mutationFn: (feedbackData: { feedback: FeedbackProps; uuid: string }) =>
      createLeadFeedback(feedbackData.feedback, feedbackData.uuid),
  })

  useEffect(() => {
    emailjs.init({ publicKey: EMAILJS_PUBLIC_KEY })
  }, [])

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const formControllers = FEEDBACK_QUESTIONS.map((question) => {
    const { field } = useController({
      name: question.key,
      control,
      rules: question.required ? { required: t('errors.fieldRequired') } : {},
      defaultValue: '',
    })

    return { question, field }
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    sendGACustomEvent({
      context: GAEventContext.LEAD,
      element: GAEventElement.FEEDBACK_FORM,
      action: GAEventAction.SUBMIT,
    })

    setIsFormSubmitting(true)

    if (choosenFeedback !== null) {
      try {
        if (process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT_TYPE.PRODUCTION) {
          await emailjs.send(
            EMAILJS_SERVICE_ID || '',
            EMAILJS_FEEDBACK_TEMPLATE_ID || '',
            {
              projectUuid,
              projectLink: window.location.href,
              status: choosenFeedback === true ? `GO ✅` : 'NO GO ❌',
              question1: data.question1,
              question2: data.question2,
              question3: data.question3,
              to_email: EMAILJS_FEEDBACK_TO_EMAIL,
              from_user_displayName:
                user?.displayName || 'No user name available',
            }
          )
        }

        const feedbackContent = FEEDBACK_QUESTIONS.map((question) => ({
          question: t(question.translationKey, { lng: 'en' }),
          answer: data[question.key],
        }))

        const feedbackData: FeedbackProps = {
          feedbackPositive: choosenFeedback,
          feedbackContent: feedbackContent,
        }

        await feedbackMutation.mutateAsync({
          feedback: feedbackData,
          uuid: projectUuid,
        })

        notification.success({
          message: t(
            'views.developer.dashboard.lead.feedbackForm.feedbackSubmittedMessage'
          ),
          description: t(
            'views.developer.dashboard.lead.feedbackForm.feedbackSubmittedDescription'
          ),
          placement: 'topRight',
          duration: 5,
        })

        handleGiveFeedback(choosenFeedback)
      } catch (error) {
        notification.error({
          message: t('errors.error'),
          description: t('errors.cantSubmitFeedback'),
          placement: 'topRight',
          duration: 5,
        })

        if (process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT_TYPE.PRODUCTION)
          await emailjs.send(
            EMAILJS_SERVICE_ID || '',
            EMAILJS_ERROR_TEMPLATE_ID || '',
            {
              projectUuid,
              projectLink: window.location.href,
              question1: data.question1,
              question2: data.question2,
              question3: data.question3,
              message: t('errorSubmittingFeedback'),
              to_email: EMAILJS_SUPPORT_TO_EMAIL,
              from_user_displayName:
                user?.displayName || 'No user name available',
            }
          )
      } finally {
        setIsFormSubmitting(false)
      }
    }
  }

  const RequiredMark = () => (
    <span className="ml-1 text-right text-xs text-coquelicot">*</span>
  )

  const { TextArea } = Input

  return (
    <Form
      onFinish={handleSubmit(onSubmit)}
      layout="vertical"
      className="mt-5 flex flex-col gap-5 border-0 border-t-[1px] border-solid border-strongWind pt-5"
    >
      {formControllers.map(({ question, field }) => (
        <Form.Item
          key={question.key}
          className="mb-0"
          validateStatus={errors[question.key] ? 'error' : ''}
          help={errors[question.key]?.message?.toString()}
          label={
            <label
              htmlFor={question.key}
              className="font-secondary text-xs uppercase"
            >
              {t(question.translationKey)}
              {question.required && <RequiredMark />}
            </label>
          }
        >
          <TextArea
            {...field}
            placeholder={t('commons.enterYourAnswer')}
            className="input"
          />
        </Form.Item>
      ))}
      <Form.Item className="mb-0 w-full">
        <div className="flex w-full items-center justify-between">
          <Button
            htmlType="submit"
            style={isFormSubmitting ? 'disabled' : 'secondary'}
            loading={isFormSubmitting}
          >
            {t('views.developer.dashboard.lead.feedbackForm.submitFeedback')}
          </Button>

          <div className="flex items-center">
            <RequiredMark />
            <span className="ml-1 text-sm">{t('commons.requiredField')}</span>
          </div>
        </div>
      </Form.Item>
    </Form>
  )
}

export default FeedbackForm
