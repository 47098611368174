import { Outlet, Link } from '@tanstack/react-router'

import LanguageSwitcher from 'components/commons/LanguageSwitcher'
import ProfileDropdown from 'components/layouts/ProfileDropdown'
import MobileMenu from 'components/layouts/Menu/MobileMenu'
import InicioTextLogo from 'components/SVG/InicioTextLogo'
import Sidebar from 'components/layouts/Menu/DesktopMenu'

export const MENU_SIZE = '60px'

const ProtectedLayout = () => {
  const headerStyle = {
    height: MENU_SIZE,
  }

  const sidebarStyle = {
    minHeight: `calc(100vh - ${MENU_SIZE})`,
    maxHeight: `calc(100vh - ${MENU_SIZE})`,
  }

  return (
    <div className="flex min-h-screen max-w-full flex-col">
      <header
        className="relative z-10 flex items-center justify-between gap-5 bg-white pl-[25px] pr-10 shadow-[0_4px_3px_-2px_rgb(0,0,0,0.1)]"
        style={headerStyle}
      >
        <Link to="/">
          <InicioTextLogo width="70" />
        </Link>

        <div className="flex items-center gap-5">
          <LanguageSwitcher />

          <div className="hidden md:block">
            <ProfileDropdown />
          </div>

          <div className="block md:hidden">
            <MobileMenu />
          </div>
        </div>
      </header>

      <div className="flex">
        <div className="hidden w-fit md:block">
          <div
            style={{ ...sidebarStyle }}
            className="relative z-20 w-fit bg-white"
          >
            <Sidebar />
          </div>
        </div>

        <div className="max-w-full flex-1">
          <div
            className="relative overflow-scroll bg-wind"
            style={{ zIndex: 0 }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProtectedLayout
