import { useTranslation } from 'react-i18next'

import Category from 'views/developer/dashboard/lead/components/Category'
import VoteBar from 'views/developer/dashboard/lead/components/VoteBar'
import { queryClient } from 'components/commons/QueryClientProvider'
import { Category as CategoryType, Project } from 'types'
import CustomCard from 'components/commons/CustomCard'

const LeadPageRightPart = ({
  selectedPicturePath,
  handleChangePicturePath,
  projectUuid,
  project,
}: {
  selectedPicturePath: string
  handleChangePicturePath: (path?: string) => void
  projectUuid: string
  project: Project
}) => {
  const { t } = useTranslation()
  const handleGiveFeedback = (value: boolean) => {
    queryClient.setQueryData(
      ['project', projectUuid],
      (oldData: Project | undefined) => {
        if (!oldData) return oldData

        return {
          ...oldData,
          hasFeedback: true,
          isFeedbackPositive: value,
        }
      }
    )
  }

  return (
    <div id="right-part" className="flex flex-1 flex-col gap-4">
      {!project?.hasFeedback && (
        <VoteBar
          projectUuid={projectUuid}
          handleGiveFeedback={handleGiveFeedback}
        />
      )}

      <CustomCard
        title={t(
          'views.developer.dashboard.lead.leadPageRightPart.criteriaMatchingSpecification'
        )}
      >
        <div className="ml-5 flex flex-col">
          {project?.data?.map((category: CategoryType) => (
            <div
              key={category.title}
              className="border-0 border-wind py-7 [&:not(:last-child)]:border-b-[1px]"
            >
              <Category
                category={category}
                selectedPicturePath={selectedPicturePath}
                handleChangePicturePath={handleChangePicturePath}
              />
            </div>
          ))}
        </div>
      </CustomCard>
    </div>
  )
}

export default LeadPageRightPart
