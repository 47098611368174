import { useState } from 'react'

import LeadPageRightPart from 'views/developer/dashboard/lead/components/LeadPageRightPart'
import LeadPageLeftPart from 'views/developer/dashboard/lead/components/LeadPageLeftPart'
import { Project } from 'types'

const LeadPageContent = ({
  project,
  projectUuid,
}: {
  project: Project
  projectUuid: string
}) => {
  const [selectedPicturePath, setPicturePath] = useState(
    project.mainMapPicturePath || ''
  )

  const handleChangePicturePath = (path?: string) => {
    setPicturePath(path || project.mainMapPicturePath || '')
  }

  return (
    <div id="main-container" className="flex w-full flex-col gap-4 lg:flex-row">
      <LeadPageLeftPart
        project={project}
        selectedPicturePath={selectedPicturePath}
      />
      <LeadPageRightPart
        project={project}
        selectedPicturePath={selectedPicturePath}
        handleChangePicturePath={handleChangePicturePath}
        projectUuid={projectUuid}
      />
    </div>
  )
}

export default LeadPageContent
