import { AwsRum, AwsRumConfig } from 'aws-rum-web'

let rumInstance: AwsRum | null = null

const {
  REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
  REACT_APP_AWS_RUM_ENDPOINT,
  REACT_APP_AWS_RUM_APPLICATION_ID,
  REACT_APP_AWS_RUM_REGION,
} = process.env

interface EventData {
  [key: string]: string | number | boolean | undefined | object
}

const awsRum = async (): Promise<AwsRum | void> => {
  if (
    !REACT_APP_AWS_RUM_IDENTITY_POOL_ID ||
    !REACT_APP_AWS_RUM_ENDPOINT ||
    !REACT_APP_AWS_RUM_APPLICATION_ID ||
    !REACT_APP_AWS_RUM_REGION
  )
    return

  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      identityPoolId: REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
      endpoint: REACT_APP_AWS_RUM_ENDPOINT,
      telemetries: ['performance', 'http'],
      allowCookies: true,
      enableXRay: false,
    }

    const APPLICATION_ID: string = REACT_APP_AWS_RUM_APPLICATION_ID
    const APPLICATION_VERSION: string = '1.0.0'
    const APPLICATION_REGION: string = REACT_APP_AWS_RUM_REGION

    rumInstance = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    )

    return rumInstance
  } catch (error) {
    console.error('Failed to initialize AWS RUM', error)
  }
}

export const recordRumEvent = (eventName: string, eventData?: EventData) => {
  if (rumInstance && eventData !== undefined) {
    try {
      rumInstance.recordEvent(eventName, eventData)
    } catch (error) {
      console.error('Failed to record RUM event', error)
    }
  }
}

export default awsRum
