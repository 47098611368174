import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientConfig } from '@tanstack/react-query'
import React, { useState } from 'react'

const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24,
    },
  },
}

export const queryClient = new QueryClient(queryClientConfig)

const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

const QueryClientProvider = ({ children }: { children: React.ReactNode }) => {
  const [isHydrated, setIsHydrated] = useState(false)

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister,
        maxAge: Infinity,
        dehydrateOptions: {
          shouldDehydrateQuery: (query) => {
            return (
              query.queryKey[0] === 'user' || query.queryKey[0] === 'projects'
            )
          },
        },
      }}
      onSuccess={() => {
        setIsHydrated(true)
      }}
    >
      {isHydrated ? children : null}
      <ReactQueryDevtools initialIsOpen={false} />
    </PersistQueryClientProvider>
  )
}

export default QueryClientProvider
