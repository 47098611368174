import React, { useState, useEffect } from 'react'
import { Dropdown, Space } from 'antd'
import type { MenuProps } from 'antd'
import i18n from 'i18next'

import { GAEventAction, GAEventContext, GAEventElement } from 'types'
import sendGACustomEvent from 'utils/gaCustomEventSender'
import { FALLBACK_LANGUAGE } from 'utils/i18n'

const items: MenuProps['items'] = [
  {
    key: 'en',
    label: (
      <span className="flex gap-2 py-1 pr-10">
        <img
          src={`/assets/images/flags/en.png`}
          alt="English flag icon"
          className="h-[20px] w-[20px]"
        />
        English
      </span>
    ),
  },
  {
    key: 'it',
    label: (
      <span className="flex gap-2 py-1 pr-10">
        <img
          src={`/assets/images/flags/it.png`}
          alt="Italian flag icon"
          className="h-[20px] w-[20px]"
        />
        Italiano
      </span>
    ),
  },
]

const LanguageSwitcher: React.FC = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>(() => {
    return localStorage.getItem('i18nextLng') || FALLBACK_LANGUAGE
  })
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage)
    localStorage.setItem('i18nextLng', selectedLanguage)
  }, [selectedLanguage])

  const handleDropdownOpen = (isOpen: boolean) => {
    setDropdownOpen(isOpen)

    sendGACustomEvent({
      context: GAEventContext.LANGUAGES,
      element: GAEventElement.DROPDOWN,
      action: isOpen ? GAEventAction.OPEN : GAEventAction.CLOSE,
    })
  }

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    sendGACustomEvent({
      context: GAEventContext.LANGUAGES,
      element: GAEventElement.ITEM,
      action: GAEventAction.CLICK,
    })

    setSelectedLanguage(e.key)
  }

  return (
    <Dropdown
      menu={{ items, onClick: handleMenuClick }}
      placement="bottomRight"
      open={dropdownOpen}
      onOpenChange={handleDropdownOpen}
    >
      <span className="flex items-center hover:cursor-pointer">
        <Space>
          <span className="flex gap-2">
            <img
              src={`/assets/images/flags/${selectedLanguage}.png`}
              alt={`${selectedLanguage} flag icon`}
              className="h-[20px] w-[20px]"
            />
          </span>
        </Space>
      </span>
    </Dropdown>
  )
}

export default LanguageSwitcher
