import apiRequest from 'utils/apiRequest'
import { RequestType } from 'types'

const getLeadImageSignedUrl = async ({
  uuid,
  fileName,
}: {
  uuid: string
  fileName: string
}) => {
  const result = await apiRequest({
    type: RequestType.GET,
    url: `${process.env.REACT_APP_SERVER_URL}/api/v1/leads/${uuid}/image-signed-url/${fileName}`,
  })

  return result
}

export default getLeadImageSignedUrl
