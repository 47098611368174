export enum ENVIRONMENT_TYPE {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

export enum CRITERON_TYPE {
  WARNING = 'warning',
  OK = 'ok',
  NOT_OK = 'nok',
  INFO = 'info',
}

export type SideMenuItem = {
  title: string
  type?: 'category' | 'item' | 'dropdown'
  children?: SideMenuItem[]
  key?: number
  icon?: ({ size, color }: { size: number; color: string }) => JSX.Element
  path?: string
}

export interface SideMenuCategory {
  title: string
  type?: 'category' | 'item' | 'dropdown'
  children?: SideMenuItem[]
}

export type User = {
  uuid: string
  displayName: string
  email: string
}

export type Criterion = {
  key?: string
  title: string
  description?: string
  type: CRITERON_TYPE
  value?: string
  items?: Criterion[]
  criterionMapPicturePath?: string
  data?: SubstationCriterionData
  children?: Criterion[]
}

export type SubstationCriterionData = {
  id: number
  title: string
  transformer_saturation: 'green' | 'yellow' | 'orange' | 'red'
  operator: string | null
  voltage: string[]
  items: Criterion[]
  name?: string
}

export type SubstationQueueItemType = {
  id: number
  title: string
  key: string
  description: string
  type: CRITERON_TYPE
  submission_date: string
  projectPower: number
  projectDeveloper: string
  project_title: string
  projectUrl: string
  projectStage: string
  probability: number
  location: string
}

export type Specification = {
  id: number
  title: string
  key: string
  data: Category[]
}

export interface Score {
  key: string
  title: string
  value: number
}

export interface Category {
  title: string
  description: string
  criteria: Criterion[]
}

export interface ProjectItemsList {
  items: Project[]
}

export interface Project {
  uuid: string
  mainMapPicturePath: string
  specification: string
  zone: string
  region: string
  province: string
  city: string
  area: string
  scores?: Score[]
  data: Category[]
  hasFeedback?: boolean
  isFeedbackPositive?: boolean
}

export interface LeadsResponse {
  items: Project[]
  total: number
  page: number
  pages: number
}

export enum LogType {
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  REQUEST = 'request',
}

export enum RequestType {
  GET = 'GET',
  POST = 'POST',
}
export enum GAEventContext {
  BREADCRUMB = 'breadcrumb',
  LANGUAGES = 'languages',
  LEAD = 'lead',
  LOGIN = 'login',
  LOGOUT = 'logout',
  SIDEBAR = 'sidebar',
  SPECIFICATION = 'specification',
}

export enum GAEventElement {
  BUTTON = 'button',
  CRITERIA_CATEGORY = 'criteria_category',
  DROPDOWN = 'dropdown',
  FEEDBACK_BUTTON = 'feedback_button',
  FEEDBACK_FORM = 'feedback_form',
  FILTER = 'filter',
  FORM = 'form',
  IMAGE = 'image',
  INFO_MEMO = 'info_memo',
  ITEM = 'item',
  KML = 'kml',
  MENU = 'menu',
  POPOVER = 'popover',
}

export enum GAEventAction {
  CHANGE = 'change',
  CHANGE_REVIEW = 'change_review',
  CHANGE_SPECIFICATION = 'change_specification',
  CLICK = 'click',
  CLICK_NOT_POSITIVE = 'click_not_positive',
  CLICK_POSITIVE = 'click_positive',
  CLOSE = 'close',
  DOWNLOAD = 'download',
  EXPAND = 'expand',
  OPEN = 'open',
  SHRINK = 'shrink',
  SUBMIT = 'submit',
}

export enum Environment {
  PRODUCTION = 'production',
  STAGING = 'staging',
  LOCAL = 'local',
}
