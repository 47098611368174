import { useTranslation } from 'react-i18next'
import { useEffect, useRef } from 'react'

import LeadCard from 'components/commons/LeadCard/LeadCard'
import EmptyIcon from 'components/SVG/Icons/EmptyIcon'
import Loader from 'components/commons/Loader'
import { Project } from 'types'

import theme from 'styles/theme'

const ProjectList = ({
  projects,
  onLoadMore,
  hasMore,
  isFetchingNextPage,
}: {
  projects: Project[]
  onLoadMore?: () => void
  hasMore?: boolean
  isFetchingNextPage?: boolean
}) => {
  const { t } = useTranslation()
  const loaderRef = useRef<HTMLDivElement | null>(null)

  // Fetch new pagination page when scrolling to page bottom
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          onLoadMore &&
          hasMore &&
          !isFetchingNextPage
        ) {
          onLoadMore()
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      }
    )

    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current)
      }
    }
  }, [onLoadMore, hasMore, isFetchingNextPage])

  return (
    <div className="flex w-full flex-col gap-5">
      {!!projects.length && (
        <div className="text-sm font-bold">
          <div className="text-sm font-bold lowercase">{`${projects.length} ${projects.length > 1 ? t('commons.projects') : t('commons.project')}`}</div>
        </div>
      )}

      <div className="flex flex-wrap gap-5">
        {!!projects.length &&
          projects.map((project) => (
            <div
              key={project.uuid}
              className="w-full min-w-[300px] sm:w-[calc(50%-10px)] lg:w-[calc(33.333%-13.333px)]"
            >
              <LeadCard project={project} />
            </div>
          ))}

        {projects.length === 0 && (
          <div className="flex h-[300px] w-full flex-col items-center justify-center gap-3">
            <EmptyIcon color={theme.colors.strongWind} size={40} />
            <div className="text-xl text-strongWind">
              {t('commons.noResult')}
            </div>
          </div>
        )}
      </div>

      {hasMore && (
        <div ref={loaderRef}>
          {isFetchingNextPage && (
            <div
              ref={loaderRef}
              className="flex w-full items-center justify-center"
            >
              <Loader />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ProjectList
