import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import ExternalLinkIcon from 'components/SVG/Icons/ExternalLinkIcon'
import CaretDownIcon from 'components/SVG/Icons/CaretDownIcon'
import CaretUpIcon from 'components/SVG/Icons/CaretUpIcon'
import { SubstationQueueItemType } from 'types'

const SubstationQueueItemDetails = ({
  item,
}: {
  item: SubstationQueueItemType
}) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {isOpen ? (
        <div className="flex w-full items-start justify-between gap-4 rounded-lg bg-white p-4 text-sm">
          <div className="flex flex-col gap-4">
            <div className="flex flex-wrap gap-4">
              <div className="flex flex-col gap-4">
                {item.projectDeveloper && (
                  <div className="flex flex-col">
                    <div className="font-bold">{t('commons.company')}</div>
                    <div>{item.projectDeveloper}</div>
                  </div>
                )}
                {item.projectStage && (
                  <div className="flex flex-col">
                    <div className="font-bold">
                      {t(
                        'views.developer.dashboard.lead.leadPageRightPart.authorizationProcessStatus'
                      )}
                    </div>
                    <div>{item.projectStage}</div>
                  </div>
                )}

                {item.projectPower && (
                  <div className="flex flex-col">
                    <div className="font-bold">{t('commons.power')}</div>
                    <div>
                      {(Math.round(item.projectPower * 100) / 100).toFixed(2)}{' '}
                      MW
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-4">
                {item.location && (
                  <div className="flex flex-col">
                    <div className="font-bold">{t('commons.location')}</div>
                    <div>{item.location}</div>
                  </div>
                )}

                {item.projectUrl && (
                  <div className="flex flex-col">
                    <div className="font-bold">
                      {t(
                        'views.developer.dashboard.lead.leadPageRightPart.substationQueueItemProjectLink'
                      )}
                    </div>
                    <div className="flex items-center gap-1 underline hover:cursor-pointer">
                      <a
                        href={item.projectUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-1 underline hover:cursor-pointer"
                      >
                        Open the project link
                        <ExternalLinkIcon size={15} color="black" />
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <button
            onClick={() => setIsOpen(false)}
            className="flex items-center gap-2 hover:cursor-pointer"
          >
            Close <CaretUpIcon color="black" size={15} />
          </button>
        </div>
      ) : (
        <div className="flex w-full flex-wrap items-center justify-between gap-4 rounded-lg bg-white p-4 text-sm">
          <div className="flex items-center gap-4">
            <div className="font-bold">Power</div>
            <div>
              {(Math.round(item.projectPower * 100) / 100).toFixed(2)} MW
            </div>
          </div>
          <button
            onClick={() => setIsOpen(true)}
            className="flex items-center gap-2 hover:cursor-pointer"
          >
            More details <CaretDownIcon color="black" size={15} />
          </button>
        </div>
      )}
    </>
  )
}

export default SubstationQueueItemDetails
