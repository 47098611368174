import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import getLeadImageSignedUrl from 'actions/get-lead-image-signed-url'
import ReviewStatusBadge from 'components/commons/ReviewStatusBadge'
import BrokenImageIcon from 'components/SVG/Icons/BrokenImageIcon'
import Loader from 'components/commons/Loader'

import theme from 'styles/theme'

const PictureSection = ({
  uuid,
  isViewable,
  area,
  hasFeedback,
  isFeedbackPositive,
}: {
  uuid: string
  isViewable: boolean
  area?: string
  hasFeedback?: boolean
  isFeedbackPositive?: boolean
}) => {
  const [imageLoadError, setImageLoadError] = useState(false)

  const { data, isLoading, error } = useQuery({
    queryKey: ['project_picture', uuid],
    queryFn: () => getLeadImageSignedUrl({ uuid, fileName: 'field.png' }),
    staleTime: 0,
    gcTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const handleImageError = () => {
    setImageLoadError(true)
  }

  const hasError = error || imageLoadError

  return (
    <div
      className="relative h-[300px] w-[100%] overflow-hidden rounded-xl bg-wind"
      onContextMenu={!isViewable ? (e) => e.preventDefault() : undefined}
    >
      {isLoading && <Loader />}

      {hasError && !isLoading && (
        <div className="flex h-full w-full items-center justify-center">
          <BrokenImageIcon size={40} color={theme.colors.strongWind} />
        </div>
      )}

      {data && !isLoading && !hasError && (
        <img
          key={Date.now()}
          src={data?.url}
          alt="Project image"
          className={!isViewable ? 'pointer-events-none blur-md' : ''}
          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
          draggable="false"
          onError={handleImageError}
        />
      )}

      <div className="absolute bottom-0 left-0 right-0 h-[30px] bg-black opacity-30"></div>

      <div className="absolute bottom-[30px] left-0 right-0 h-[70px] bg-gradient-to-t from-black/30 to-transparent"></div>

      {isViewable && (
        <span
          className={`absolute bottom-2 left-2 text-lg font-bold text-white`}
        >
          {area}
        </span>
      )}

      {isViewable && (
        <div className="absolute right-2 top-2">
          <ReviewStatusBadge
            hasFeedback={hasFeedback}
            isFeedbackPositive={isFeedbackPositive}
          />
        </div>
      )}
    </div>
  )
}

export default PictureSection
