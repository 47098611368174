import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import ArrowBendDownRightIcon from 'components/SVG/Icons/ArrowBendDownRightIcon'
import Criterion from 'views/developer/dashboard/lead/components/Criterion'
import CaretDownIcon from 'components/SVG/Icons/CaretDownIcon'
import CaretUpIcon from 'components/SVG/Icons/CaretUpIcon'
import sendGACustomEvent from 'utils/gaCustomEventSender'
import {
  CRITERON_TYPE,
  Category as CategoryType,
  GAEventAction,
  GAEventContext,
  GAEventElement,
} from 'types'

const EstimationDot = ({ type }: { type: CRITERON_TYPE }) => {
  const color =
    (type === CRITERON_TYPE.OK && 'bg-grass') ||
    (type === CRITERON_TYPE.WARNING && 'bg-sun') ||
    (type === CRITERON_TYPE.NOT_OK && 'bg-coquelicot')

  return <div className={`h-[7px] w-[20px] rounded-full ${color}`}></div>
}

const Category = ({
  category,
  selectedPicturePath,
  handleChangePicturePath,
}: {
  category: CategoryType
  selectedPicturePath: string
  handleChangePicturePath: (value?: string) => void
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()

  const handleClickOnCategory = () => {
    sendGACustomEvent({
      context: GAEventContext.LEAD,
      element: GAEventElement.CRITERIA_CATEGORY,
      action: isOpen ? GAEventAction.OPEN : GAEventAction.CLOSE,
    })

    setIsOpen(!isOpen)
  }

  return (
    <div>
      <div
        className="flex flex-col items-start gap-2 hover:cursor-pointer lg:flex-row lg:items-center lg:justify-between"
        onClick={handleClickOnCategory}
      >
        <div className="flex flex-1 items-center justify-start gap-2">
          <div>
            {isOpen && <CaretUpIcon color="black" size={15} />}
            {!isOpen && <CaretDownIcon color="black" size={15} />}
          </div>
          <div className="font-bold">{t(category.title)}</div>
        </div>
        <div className="flex flex-wrap items-center justify-start gap-2 lg:justify-end">
          <div className="text-sm">{t(category.description)}</div>
          <div className="flex flex-wrap gap-1">
            {category.criteria.map((criterion, i) => {
              return (
                <div key={`${criterion.title}-${i}`} className="flex gap-1">
                  <EstimationDot type={criterion.type as CRITERON_TYPE} />
                </div>
              )
            })}
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="mt-6 flex flex-col gap-2 md:ml-5">
          {category.criteria.map((criterion, i) => {
            return (
              <div key={`${criterion.title}-${i}`}>
                <Criterion
                  criterion={criterion}
                  handleChangePicturePath={handleChangePicturePath}
                  selectedPicturePath={selectedPicturePath}
                />

                {criterion.children?.map((children, i) => {
                  return (
                    <div
                      key={`${criterion.title}-${children.title}-${i}`}
                      className="flex items-start justify-center pt-2"
                    >
                      <div className="p-5">
                        <ArrowBendDownRightIcon color="black" size={40} />
                      </div>
                      <Criterion
                        criterion={children}
                        handleChangePicturePath={handleChangePicturePath}
                        selectedPicturePath={selectedPicturePath}
                      />
                    </div>
                  )
                })}

                {criterion?.key === 'substation' &&
                  criterion.items?.map((children, i) => {
                    return (
                      <div
                        key={`${criterion.title}-${children.title}-${i}`}
                        className="flex items-start justify-center pt-2"
                      >
                        <div className="p-5">
                          <ArrowBendDownRightIcon color="black" size={40} />
                        </div>
                        <Criterion
                          criterion={children}
                          handleChangePicturePath={handleChangePicturePath}
                          selectedPicturePath={selectedPicturePath}
                        />
                      </div>
                    )
                  })}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
export default Category
