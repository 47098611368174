import { ItemType } from 'antd/es/menu/interface'
import { Drawer, Menu, MenuProps } from 'antd'
import { useState } from 'react'

import BurgerMenuIcon from 'components/SVG/Icons/BurgerMenuIcon'
import LogoutSection from 'components/commons/Logout'
import useSideMenuItems from 'hooks/useSideMenuItems'

const MobileMenu = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [stateOpenKeys, setStateOpenKeys] = useState(['2', '23'])
  const sideMenuItems = useSideMenuItems()

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  interface LevelKeysProps {
    key?: string
    children?: LevelKeysProps[]
  }

  const getLevelKeys = (items1: LevelKeysProps[]) => {
    const key: Record<string, number> = {}
    const func = (items2: LevelKeysProps[], level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level
        }
        if (item.children) {
          func(item.children, level + 1)
        }
      })
    }
    func(items1)
    return key
  }

  const levelKeys = getLevelKeys(sideMenuItems.items as LevelKeysProps[])

  const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    )
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey])

      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      )
    } else {
      setStateOpenKeys(openKeys)
    }
  }

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    const findItem = (
      items: ItemType[],
      targetKey: string
    ): ItemType | undefined => {
      for (const item of items) {
        if (!item) continue
        if ('key' in item && item.key === targetKey) return item
        if ('children' in item && item.children) {
          const found = findItem(item.children, targetKey)
          if (found) return found
        }
      }
      return undefined
    }

    const item = findItem(sideMenuItems.items, key)
    if (item && !('children' in item)) {
      setIsDrawerOpen(false)
    }
  }

  return (
    <>
      <button onClick={toggleDrawer} className="p-2">
        <BurgerMenuIcon color="black" size={25} />
      </button>

      <Drawer
        open={isDrawerOpen}
        onClose={toggleDrawer}
        extra={<LogoutSection />}
      >
        <Menu
          mode="inline"
          onOpenChange={onOpenChange}
          onClick={handleMenuClick}
          style={{ width: '100%' }}
          items={sideMenuItems.items}
          selectedKeys={sideMenuItems.selectedKeys}
          id="mobile-menu"
        />
      </Drawer>
    </>
  )
}

export default MobileMenu
