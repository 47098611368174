import { useNavigate, useLocation } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { MenuProps } from 'antd'
import React from 'react'

import ListChecksIcon from 'components/SVG/Icons/ListChecksIcon'
import SubstractIcon from 'components/SVG/Icons/SubstractIcon'
import PuzzleIcon from 'components/SVG/Icons/PuzzleIcon'
import useSpecifications from 'hooks/useSpecifications'

type MenuItem = Required<MenuProps>['items'][number]

const SideMenuItems = () => {
  const { t } = useTranslation()
  const { specifications } = useSpecifications()
  const navigate = useNavigate()
  const location = useLocation()

  const handleClick = (path: string) => {
    navigate({ to: path })
  }

  const sideMenuItems: MenuItem[] = [
    {
      type: 'group',
      label: t('commons.projects'),
      children: [
        {
          key: '/developer/dashboard',
          label: t('commons.allProjects'),
          onClick: () => handleClick('/developer/dashboard'),
          icon: React.createElement(SubstractIcon, {
            color: 'black',
            size: 18,
          }),
        },
      ],
    },
    {
      type: 'group',
      label: t('commons.specifications'),
      children: [
        {
          key: '/developer/dashboard/specification',
          label: t('commons.allSpecifications'),
          children: specifications
            ? specifications.map((specification) => ({
                key: `/developer/dashboard/specification/${specification.id}`,
                label: specification.title,
                onClick: () =>
                  handleClick(
                    `/developer/dashboard/specification/${specification.id}`
                  ),
              }))
            : [],
          icon: React.createElement(ListChecksIcon, {
            color: 'black',
            size: 18,
          }),
        },
        {
          key: '/developer/dashboard/specification/new',
          label: t('commons.shareYourNeeds'),
          onClick: () => handleClick('/developer/dashboard/specification/new'),
          icon: React.createElement(PuzzleIcon, {
            color: 'black',
            size: 18,
          }),
        },
      ],
    },
  ]

  const getSelectedKeys = (path: string): string[] => {
    const selectedKeys: string[] = [path]

    const findParentKey = (
      items: MenuItem[],
      currentPath: string
    ): string | null => {
      for (const item of items) {
        if (item && 'children' in item && item.children) {
          for (const child of item.children) {
            if (child?.key === currentPath) {
              if (item.type !== 'group') {
                return String(item.key)
              }
              return null
            }

            const parentKey = findParentKey([child as MenuItem], currentPath)
            if (parentKey) {
              return parentKey
            }
          }
        }
      }
      return null
    }

    const parentKey = findParentKey(sideMenuItems, path)
    if (parentKey) {
      selectedKeys.push(parentKey)
    }

    return selectedKeys
  }

  const selectedKeys = getSelectedKeys(location.pathname)

  return {
    items: sideMenuItems,
    selectedKeys,
  }
}

export default SideMenuItems
