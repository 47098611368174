import { useTranslation } from 'react-i18next'
import {
  createRouter,
  createRootRoute,
  createRoute,
} from '@tanstack/react-router'

import ErrorView from 'views/developer/dashboard/lead/components/ErrorView'
import NewSpecification from 'views/developer/dashboard/newSpecification'
import Specification from 'views/developer/dashboard/specification'
import ProtectedLayout from 'components/layouts/ProtectedLayout'
import checkUserSession from 'utils/checkUserSession'
import Leads from 'views/developer/dashboard/leads'
import Lead from 'views/developer/dashboard/lead'
import LoginPage from 'views/login'
import HomePage from 'views/home'

const rootRoute = createRootRoute()

const protectedRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/developer/dashboard',
  component: ProtectedLayout,
  beforeLoad: checkUserSession,
})

const homeRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  component: HomePage,
})

const loginRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/login',
  component: LoginPage,
})

const dashboardRoute = createRoute({
  getParentRoute: () => protectedRoute,
  path: '/',
  component: Leads,
})

const projectRoute = createRoute({
  getParentRoute: () => protectedRoute,
  path: '/project/$id',
  component: Lead,
})

const specificationRoute = createRoute({
  getParentRoute: () => protectedRoute,
  path: '/specification/$id',
  component: Specification,
})

const newSpecificationRoute = createRoute({
  getParentRoute: () => protectedRoute,
  path: '/specification/new',
  component: NewSpecification,
})

const notFoundRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '*',
  component: () => {
    const { t } = useTranslation()
    return (
      <ErrorView
        message={t('errors.pageNotFoundOrNotAccessibleMessage')}
        buttonMessage={t('commons.backToHome')}
        onNavigateButtonClickPath="/"
      />
    )
  },
})

const routeTree = rootRoute.addChildren([
  homeRoute,
  loginRoute,
  protectedRoute.addChildren([
    dashboardRoute,
    projectRoute,
    specificationRoute,
    newSpecificationRoute,
  ]),
  notFoundRoute,
])

export const router = createRouter({ routeTree })

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}
