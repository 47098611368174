import { useQuery } from '@tanstack/react-query'

import getLead from 'actions/get-lead'

export const getProjectById = (uuid: string) => {
  const {
    data: project,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['project', uuid],
    queryFn: () => getLead({ uuid }),
    staleTime: 0,
    gcTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  return {
    data: project,
    isLoading,
    error,
  }
}
