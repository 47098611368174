import { useTranslation } from 'react-i18next'

import ThumbDownIcon from 'components/SVG/Icons/ThumbDownIcon'
import ThumbUpIcon from 'components/SVG/Icons/ThumbUpIcon'
import ClockIcon from 'components/SVG/Icons/ClockIcon'
import Badge from 'components/commons/Badge'

import theme from 'styles/theme'

const ReviewStatusBadge = ({
  hasFeedback,
  isFeedbackPositive,
}: {
  hasFeedback?: boolean
  isFeedbackPositive?: boolean
}) => {
  const { t } = useTranslation()

  if (hasFeedback && isFeedbackPositive) {
    return (
      <Badge
        text={t('commonsComponents.reviewStatusBadge.reviewed')}
        icon={<ThumbUpIcon size={15} color={theme.colors.strongGrass} />}
        backgroundColor={theme.colors.grass}
        textColor={theme.colors.strongGrass}
      />
    )
  }

  if (hasFeedback && !isFeedbackPositive) {
    return (
      <Badge
        text={t('commonsComponents.reviewStatusBadge.reviewed')}
        icon={<ThumbDownIcon size={15} color="white" />}
        backgroundColor={theme.colors.coquelicot}
        textColor="white"
      />
    )
  }

  if (!hasFeedback) {
    return (
      <Badge
        text={t('commonsComponents.reviewStatusBadge.pendingReview')}
        icon={<ClockIcon size={15} color={theme.colors.soil} />}
        backgroundColor={theme.colors.wind}
        textColor={theme.colors.soil}
      />
    )
  }

  return null
}

export default ReviewStatusBadge
