import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import ProgressGradient from 'components/commons/ProgressGradient'
import CaretDownIcon from 'components/SVG/Icons/CaretDownIcon'
import CaretUpIcon from 'components/SVG/Icons/CaretUpIcon'
import getFormattedNumber from 'utils/getFormattedNumber'
import { SubstationCriterionData } from 'types'

const SubstationDetails = ({
  substation,
}: {
  substation: SubstationCriterionData
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      {isOpen ? (
        <div className="flex w-full items-start justify-between gap-4 rounded-lg bg-white p-4 text-sm">
          <div className="flex flex-col gap-4">
            {substation.title && (
              <div className="flex flex-col">
                <div className="font-bold">{t('commons.substation')}</div>
                <div className="text-wrap">{substation.title}</div>
              </div>
            )}

            {!!substation?.name && (
              <div className="flex flex-col">
                <div className="font-bold">{t('commons.name')}</div>
                <div>{substation?.name}</div>
              </div>
            )}

            {substation.operator && (
              <div className="flex flex-col">
                <div className="font-bold">{t('commons.operator')}</div>
                <div className="capitalize">{substation.operator}</div>
              </div>
            )}

            {substation.voltage?.length && (
              <div className="flex flex-col">
                <div className="font-bold">
                  {t(
                    'views.developer.dashboard.lead.leadPageRightPart.supposedVoltage'
                  )}
                </div>
                <div>
                  {substation.voltage
                    .map((v) => `${getFormattedNumber(parseInt(v))} V`)
                    .join(', ')}
                </div>
              </div>
            )}

            {!!substation?.items?.length && (
              <div className="flex flex-col">
                <div className="font-bold">
                  {t(
                    'views.developer.dashboard.lead.leadPageRightPart.potentialConnectedProjectNumbers'
                  )}
                </div>
                <div>{substation?.items?.length}</div>
              </div>
            )}

            {substation?.transformer_saturation && (
              <div className="flex flex-col" key={substation.id}>
                <div className="font-bold">{t('commons.saturation')}</div>
                <div className="w-[100px] md:w-[200px]">
                  <ProgressGradient color={substation.transformer_saturation} />
                </div>
              </div>
            )}
          </div>
          <button
            onClick={() => setIsOpen(false)}
            className="flex items-center gap-2 hover:cursor-pointer"
          >
            {t('commons.close')} <CaretUpIcon color="black" size={15} />
          </button>
        </div>
      ) : (
        <div className="flex w-full flex-wrap items-center justify-between gap-4 rounded-lg bg-white p-4 text-sm">
          <div className="flex items-center gap-4">
            {substation?.transformer_saturation && (
              <>
                <div className="font-bold capitalize">
                  {t('commons.saturation')}
                </div>
                <div className="w-[100px] md:w-[200px]">
                  {substation?.transformer_saturation && (
                    <ProgressGradient
                      color={substation?.transformer_saturation}
                    />
                  )}
                </div>
              </>
            )}

            {!substation?.transformer_saturation && substation.operator && (
              <div className="flex gap-2">
                <div className="font-bold">{t('commons.operator')}</div>
                <div className="capitalize">{substation.operator}</div>
              </div>
            )}
          </div>
          <button
            onClick={() => setIsOpen(true)}
            className="flex items-center gap-2 hover:cursor-pointer"
          >
            {t('commons.moreDetails')} <CaretDownIcon color="black" size={15} />
          </button>
        </div>
      )}
    </>
  )
}

export default SubstationDetails
