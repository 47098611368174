import { useForm, useController } from 'react-hook-form'
import { Form, Input, Modal, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import emailjs from '@emailjs/browser'

import Button from 'components/commons/Button'

type AccessRequestInputs = {
  name: string
  email: string
}

const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY
const EMAILJS_ACCESS_REQUEST_TEMPLATE_ID =
  process.env.REACT_APP_EMAILJS_ACCESS_REQUEST_TEMPLATE_ID
const EMAILJS_ERROR_TEMPLATE_ID =
  process.env.REACT_APP_EMAILJS_ERROR_TEMPLATE_ID
const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID
const EMAILJS_REQUEST_ACCESS_TO_EMAIL =
  process.env.REACT_APP_EMAILJS_REQUEST_ACCESS_TO_EMAIL
const EMAILJS_SUPPORT_TO_EMAIL = process.env.REACT_APP_EMAILJS_SUPPORT_TO_EMAIL

const AccessRequest = () => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isFormSubmiting, setIsFormSubmitting] = useState(false)

  const {
    control: accessControl,
    handleSubmit: handleAccessSubmit,
    formState: { errors: accessErrors },
    reset: resetAccessForm,
  } = useForm<AccessRequestInputs>()

  const openModal = () => {
    resetAccessForm()
    setIsModalOpen(true)
  }

  const onAccessSubmit = async (data: AccessRequestInputs) => {
    setIsFormSubmitting(true)

    try {
      if (
        EMAILJS_PUBLIC_KEY &&
        EMAILJS_SERVICE_ID &&
        EMAILJS_ACCESS_REQUEST_TEMPLATE_ID
      ) {
        await emailjs.send(
          EMAILJS_SERVICE_ID,
          EMAILJS_ACCESS_REQUEST_TEMPLATE_ID,
          {
            name: data.name,
            email: data.email,
            to_email: EMAILJS_REQUEST_ACCESS_TO_EMAIL,
          }
        )
      }

      notification.success({
        message: t(
          'views.login.accessRequestForm.confirmationNotificationTitle'
        ),
        description: t(
          'views.login.accessRequestForm.confirmationNotificationMessage'
        ),
        placement: 'topRight',
        duration: 5,
      })

      setIsFormSubmitting(false)
      setIsModalOpen(false)
    } catch (error) {
      notification.error({
        message: t('errors.error'),
        description: t('errors.cantSendAccessRequest'),
        placement: 'topRight',
        duration: 5,
      })

      if (EMAILJS_PUBLIC_KEY && EMAILJS_SERVICE_ID && EMAILJS_ERROR_TEMPLATE_ID)
        await emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_ERROR_TEMPLATE_ID, {
          name: data.name,
          email: data.email,
          to_email: EMAILJS_SUPPORT_TO_EMAIL,
        })

      setIsFormSubmitting(false)
    }
  }

  const { field: accessNameField } = useController({
    name: 'name',
    control: accessControl,
    rules: { required: t('errors.nameRequired') },
    defaultValue: '',
  })

  const { field: accessEmailField } = useController({
    name: 'email',
    control: accessControl,
    rules: {
      required: t('errors.emailRequired'),
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: t('errors.invalidEmail'),
      },
    },
    defaultValue: '',
  })

  const closeModal = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    emailjs.init({ publicKey: EMAILJS_PUBLIC_KEY })
  }, [])

  return (
    <>
      <div className="border-lg rounded-lg bg-wind p-5">
        <div className="mb-5 text-center">
          {t('views.login.accessRequestForm.CTABlockTitle')}
        </div>

        <Button style={'primary'} onClick={openModal}>
          {t('views.login.accessRequestForm.requestYourAccessButtonMessage')}
        </Button>
      </div>
      <Modal
        title={t('views.login.accessRequestForm.requestYourAccessModalTitle')}
        open={isModalOpen}
        onCancel={closeModal}
        footer={null}
        className="w-[450px] max-w-[90%]"
      >
        <Form
          onFinish={handleAccessSubmit(onAccessSubmit)}
          layout="vertical"
          className="flex flex-col gap-2"
        >
          <div className="mb-5 mt-4 mt-5 rounded-lg bg-wind p-4">
            {t('views.login.accessRequestForm.requestYourAccessNotice')}
          </div>
          <Form.Item
            validateStatus={accessErrors.name ? 'error' : ''}
            help={accessErrors.name?.message?.toString()}
            label={
              <label
                htmlFor="email"
                className="font-secondary text-xs uppercase"
              >
                {t('commons.name')}
              </label>
            }
          >
            <Input
              {...accessNameField}
              placeholder={t('views.login.accessRequestForm.namePlaceholder')}
            />
          </Form.Item>
          <Form.Item
            validateStatus={accessErrors.email ? 'error' : ''}
            help={accessErrors.email?.message?.toString()}
            label={
              <label
                htmlFor="email"
                className="font-secondary text-xs uppercase"
              >
                {t('views.login.accessRequestForm.emailLabel')}
              </label>
            }
          >
            <Input
              {...accessEmailField}
              type="email"
              placeholder={t('views.login.accessRequestForm.emailPlaceholder')}
            />
          </Form.Item>
          <Form.Item className="mt-4 flex justify-end">
            <Button
              htmlType="submit"
              style={'secondary'}
              loading={isFormSubmiting}
            >
              {t(
                'views.login.accessRequestForm.requestYourAccessSubmitButtonMessage'
              )}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AccessRequest
