import { useTranslation } from 'react-i18next'
import { Divider } from 'antd'

import CriteriaProgressSection from 'components/commons/LeadCard/components/CriteriaProgressSection'
import Picture from 'views/developer/dashboard/lead/components/Picture'
import ReviewStatusBadge from 'components/commons/ReviewStatusBadge'
import getFormattedNumber from 'utils/getFormattedNumber'
import CustomCard from 'components/commons/CustomCard'
import { Project } from 'types'

const LeadPageLeftPart = ({
  project,
  selectedPicturePath,
}: {
  project: Project
  selectedPicturePath: string
}) => {
  const { t } = useTranslation()

  return (
    <div
      id="left-part"
      className="flex w-full min-w-[330px] flex-col gap-4 md:flex-row lg:w-[30%] lg:flex-col"
    >
      <Picture uuid={project.uuid} selectedPicturePath={selectedPicturePath} />

      <CustomCard containerClassName="md:w-[50%] lg:w-full">
        <div className="flex flex-col">
          <CriteriaProgressSection scores={project?.scores || []} />

          <Divider className="my-5 border-wind" />

          <div className="flex flex-col gap-4">
            {project.zone && (
              <div className="flex flex-1 flex-col gap-1">
                <span className="font-secondary text-xs uppercase">
                  {t('commons.zone')}
                </span>
                <p>{`${project.zone}`}</p>
              </div>
            )}

            {project.region && (
              <div className="flex flex-1 flex-col gap-1">
                <span className="font-secondary text-xs uppercase">
                  {t('commons.region')}
                </span>
                <p>{`${project.region}`}</p>
              </div>
            )}

            {project.province && (
              <div className="flex flex-1 flex-col gap-1">
                <span className="font-secondary text-xs uppercase">
                  {t('commons.province')}
                </span>
                <p>{`${project.province}`}</p>
              </div>
            )}

            {project.city && (
              <div className="flex flex-1 flex-col gap-1">
                <span className="font-secondary text-xs uppercase">
                  {t('commons.city')}
                </span>
                <p>{`${project.city}`}</p>
              </div>
            )}
          </div>

          <Divider className="my-5 border-wind" />

          <div className="flex flex-1 flex-col gap-1">
            <span className="font-secondary text-xs uppercase">
              {t('views.developer.dashboard.lead.leadPageLeftPart.area')}
            </span>
            <p>{`${getFormattedNumber(parseFloat(project.area))} ha`}</p>
          </div>
        </div>

        <Divider className="my-5 border-wind" />

        <div className="flex">
          <div className="flex flex-1 flex-col gap-1">
            <span className="mb-1 font-secondary text-xs uppercase">
              {t('views.developer.dashboard.lead.leadPageLeftPart.myFeedback')}
            </span>
            <ReviewStatusBadge
              hasFeedback={project.hasFeedback}
              isFeedbackPositive={project.isFeedbackPositive}
            />
          </div>
        </div>
      </CustomCard>
    </div>
  )
}

export default LeadPageLeftPart
