const ProgressGradient = ({
  color,
}: {
  color: 'green' | 'yellow' | 'orange' | 'red'
}) => {
  const VALUES = {
    green: {
      position: 'left-[0%]',
      translate: 'translate-x-[5px]',
    },
    yellow: {
      position: 'left-[33%]',
      translate: '-translate-x-1/2',
    },
    orange: {
      position: 'left-[66%]',
      translate: '-translate-x-1/2',
    },
    red: {
      position: 'left-[100%]',
      translate: 'translate-x-[-35px]',
    },
  }

  return (
    <div className="flex h-[40px] items-center justify-center">
      <div className="relative h-[20px] w-full rounded-full bg-gradient-to-r from-green-500 via-orange-500 via-yellow-500 to-red-500 shadow-sm">
        <div
          className={`absolute -top-[5px] h-[30px] w-[30px] rounded-full bg-white shadow-inner drop-shadow ${VALUES[color].position} ${VALUES[color].translate}`}
        ></div>
      </div>
    </div>
  )
}

export default ProgressGradient
