import { GAEventAction, GAEventContext, GAEventElement, User } from 'types'
import { queryClient } from 'components/commons/QueryClientProvider'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Avatar, Dropdown, notification } from 'antd'

import deleteBrowserUserSession from 'utils/deleteBrowserUserSession'
import CaretDownIcon from 'components/SVG/Icons/CaretDownIcon'
import SignOutIcon from 'components/SVG/Icons/SignOutIcon'
import sendGACustomEvent from 'utils/gaCustomEventSender'
import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import logoutUser from 'actions/logout-user'

const ProfileDropdown = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { data: user } = useQuery({
    queryKey: ['user'],
    queryFn: () => {
      const userData = queryClient.getQueryData<User>(['user'])
      return userData || null
    },
  })

  const logoutMutation = useMutation({
    mutationFn: logoutUser,
    onSuccess: () => {
      deleteBrowserUserSession({ queryClient })
      navigate({ to: '/' })
    },
    onError: () => {
      notification.error({
        message: t('errors.error'),
        description: t('errors.unableToLogOut'),
      })
    },
  })

  const handleLogOut = () => {
    sendGACustomEvent({
      context: GAEventContext.LOGOUT,
      element: GAEventElement.BUTTON,
      action: GAEventAction.CLICK,
    })

    logoutMutation.mutate()
  }

  const menuItems = [
    {
      key: '1',
      label: (
        <div
          className="flex min-w-[180px] items-center justify-between gap-2 p-2 text-sm hover:cursor-pointer"
          onClick={handleLogOut}
        >
          <div className="text-nowrap text-xs">{t('commons.logOut')}</div>
          <div className="flex items-center justify-center">
            <SignOutIcon size={20} color="black" />
          </div>
        </div>
      ),
    },
  ]

  return (
    <Dropdown
      menu={{ items: menuItems }}
      trigger={['hover']}
      className="hover:cursor-default"
    >
      <div className="flex items-center gap-2">
        <Avatar
          style={{ verticalAlign: 'middle' }}
          size={35}
          gap={10}
          className="bg-water"
        >
          {user?.displayName[0]}
        </Avatar>

        <div className="text-sm font-semibold">{user?.displayName}</div>

        <CaretDownIcon color="#000" size={20} />
      </div>
    </Dropdown>
  )
}

export default ProfileDropdown
