import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'

import PlaceholderProjectsList from 'views/home/components/PlaceholderProjectsList'
import EuropeActivities from 'views/home/components/EuropeActivities'
import SectionTitle from 'views/home/components/HomeSectionTitle'
import HomeDataCard from 'views/home/components/HomeDataCard'
import PublicLayout from 'components/layouts/PublicLayout'
import HomeHeader from 'views/home/components/HomeHeader'
import SectionItem from 'views/home/components/StepItem'
import SignInIcon from 'components/SVG/Icons/SignInIcon'
import Button from 'components/commons/Button'

const Home = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onLoginButtonClick = () => navigate({ to: '/developer/dashboard' })

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      title: 'Home',
    })
  }, [])

  return (
    <PublicLayout
      pageHeader={<HomeHeader />}
      pageHeaderClassName="md:mt-[500px]"
      headerExtra={
        <>
          <div className="flex items-center gap-2 sm:hidden">
            <Button onClick={onLoginButtonClick} Icon={SignInIcon}>
              {t('commons.dashboard')}
            </Button>
          </div>

          <div className="flex hidden items-center gap-2 sm:block">
            <Button onClick={onLoginButtonClick} Icon={SignInIcon}>
              {t('commons.accessMyDasbhoard')}
            </Button>
          </div>
        </>
      }
    >
      <SectionTitle title={t('views.home.ourValueProposalSectionTitle')} />

      <div className="flex flex-wrap justify-between gap-5">
        <HomeDataCard
          title={t('views.home.solarOpportunitiesVolumeDataCardTitle')}
          description={t(
            'views.home.solarOpportunitiesVolumeDataCardDescription'
          )}
          backgroundImagePath="/assets/images/home-hero-2.jpg"
        />

        <HomeDataCard
          title={t('views.home.riskAssessmentDataCardTitle')}
          description={t('views.home.riskAssessmentDataCardDescription')}
          backgroundImagePath="/assets/images/home-hero-1.jpg"
        />

        <HomeDataCard
          title={t('views.home.exclusivePlatformDataCardTitle')}
          description={t('views.home.exclusivePlatformDataCardDescription')}
          backgroundImagePath="/assets/images/home-hero-3.jpg"
        />
      </div>

      <SectionTitle title={t('views.home.ourActivitiesSectionTitle')} />
      <EuropeActivities />

      <SectionTitle title={t('views.home.howItWorksSectionTitle')} />

      <div className="flex justify-center">
        <div className="flex w-[90%] max-w-[600px] flex-col gap-10">
          <SectionItem
            icon="1"
            title={t('views.home.submitSpecificationsItemTitle')}
            description={t('views.home.submitSpecificationsItemDescription')}
          />

          <SectionItem
            icon="2"
            title={t('views.home.algorithmAnalysisItemTitle')}
            description={t('views.home.algorithmAnalysisItemDescription')}
          />

          <SectionItem
            icon="3"
            title={t('views.home.contactingLandownersItemTitle')}
            description={t('views.home.contactingLandownersItemDescription')}
          />

          <SectionItem
            icon="4"
            title={t('views.home.accessRefinedListItemTitle')}
            description={t('views.home.accessRefinedListItemDescription')}
          />
        </div>
      </div>

      <SectionTitle title={t('views.home.projectsSectionTitle')} />
      <PlaceholderProjectsList />
    </PublicLayout>
  )
}

export default Home
