import { omit } from 'lodash'
import axios from 'axios'

import { queryClient } from 'components/commons/QueryClientProvider'
import { recordRumEvent } from 'utils/awsCloudWatch'
import { LogType, RequestType, User } from 'types'
import { FALLBACK_LANGUAGE } from 'utils/i18n'

const SENSITIVE_FIELDS = ['password', 'token', 'secret'] as const

const getErrorEventType = (error: Error): LogType => {
  if (axios.isAxiosError(error))
    switch (error.response?.status) {
      case 401:
        return LogType.INFO
      default:
        return LogType.ERROR
    }
  else return LogType.ERROR
}

const apiRequest = async ({
  type,
  url,
  payload,
  params,
}: {
  type: RequestType
  url: string
  payload?: object
  params?: object
}) => {
  const locale = localStorage.getItem('i18nextLng') || FALLBACK_LANGUAGE
  const user = queryClient.getQueryData<User>(['user'])

  try {
    recordRumEvent(LogType.REQUEST, {
      type,
      url,
      userUuid: user?.uuid,
      payload: omit(payload, SENSITIVE_FIELDS),
    })

    const response = await axios({
      method: type,
      url,
      headers: {
        'Content-Type': 'application/json',
        'X-Language': locale,
      },
      withCredentials: true,
      data: payload,
      params,
    })

    const data = response.data
    return data
  } catch (error) {
    if (error instanceof Error) {
      recordRumEvent(getErrorEventType(error), {
        error,
        url,
        userUuid: user?.uuid,
        payload: omit(payload, SENSITIVE_FIELDS),
      })
    } else {
      recordRumEvent(LogType.ERROR, {
        error: 'An unknown error occurred',
        url,
        userUuid: user?.uuid,
      })
    }
    throw error
  }
}

export default apiRequest
